export const countries = {
  countries: {
    title: 'Country',
    items: {
      AF: {
        key: 'AF',
        value: 'AF',
        title: 'AF',
      },
      AX: {
        key: 'AX',
        value: 'AX',
        title: 'AX',
      },
      AL: {
        key: 'AL',
        value: 'AL',
        title: 'AL',
      },
      DZ: {
        key: 'DZ',
        value: 'DZ',
        title: 'DZ',
      },
      AS: {
        key: 'AS',
        value: 'AS',
        title: 'AS',
      },
      AD: {
        key: 'AD',
        value: 'AD',
        title: 'AD',
      },
      AO: {
        key: 'AO',
        value: 'AO',
        title: 'AO',
      },
      AI: {
        key: 'AI',
        value: 'AI',
        title: 'AI',
      },
      AQ: {
        key: 'AQ',
        value: 'AQ',
        title: 'AQ',
      },
      AG: {
        key: 'AG',
        value: 'AG',
        title: 'AG',
      },
      AR: {
        key: 'AR',
        value: 'AR',
        title: 'AR',
      },
      AM: {
        key: 'AM',
        value: 'AM',
        title: 'AM',
      },
      AW: {
        key: 'AW',
        value: 'AW',
        title: 'AW',
      },
      AU: {
        key: 'AU',
        value: 'AU',
        title: 'AU',
      },
      AT: {
        key: 'AT',
        value: 'AT',
        title: 'AT',
      },
      AZ: {
        key: 'AZ',
        value: 'AZ',
        title: 'AZ',
      },
      BS: {
        key: 'BS',
        value: 'BS',
        title: 'BS',
      },
      BH: {
        key: 'BH',
        value: 'BH',
        title: 'BH',
      },
      BD: {
        key: 'BD',
        value: 'BD',
        title: 'BD',
      },
      BB: {
        key: 'BB',
        value: 'BB',
        title: 'BB',
      },
      BY: {
        key: 'BY',
        value: 'BY',
        title: 'BY',
      },
      BE: {
        key: 'BE',
        value: 'BE',
        title: 'BE',
      },
      BZ: {
        key: 'BZ',
        value: 'BZ',
        title: 'BZ',
      },
      BJ: {
        key: 'BJ',
        value: 'BJ',
        title: 'BJ',
      },
      BM: {
        key: 'BM',
        value: 'BM',
        title: 'BM',
      },
      BT: {
        key: 'BT',
        value: 'BT',
        title: 'BT',
      },
      BO: {
        key: 'BO',
        value: 'BO',
        title: 'BO',
      },
      BQ: {
        key: 'BQ',
        value: 'BQ',
        title: 'BQ',
      },
      BA: {
        key: 'BA',
        value: 'BA',
        title: 'BA',
      },
      BW: {
        key: 'BW',
        value: 'BW',
        title: 'BW',
      },
      BV: {
        key: 'BV',
        value: 'BV',
        title: 'BV',
      },
      BR: {
        key: 'BR',
        value: 'BR',
        title: 'BR',
      },
      IO: {
        key: 'IO',
        value: 'IO',
        title: 'IO',
      },
      BN: {
        key: 'BN',
        value: 'BN',
        title: 'BN',
      },
      BG: {
        key: 'BG',
        value: 'BG',
        title: 'BG',
      },
      BF: {
        key: 'BF',
        value: 'BF',
        title: 'BF',
      },
      BI: {
        key: 'BI',
        value: 'BI',
        title: 'BI',
      },
      KH: {
        key: 'KH',
        value: 'KH',
        title: 'KH',
      },
      CM: {
        key: 'CM',
        value: 'CM',
        title: 'CM',
      },
      CA: {
        key: 'CA',
        value: 'CA',
        title: 'CA',
      },
      CV: {
        key: 'CV',
        value: 'CV',
        title: 'CV',
      },
      KY: {
        key: 'KY',
        value: 'KY',
        title: 'KY',
      },
      CF: {
        key: 'CF',
        value: 'CF',
        title: 'CF',
      },
      TD: {
        key: 'TD',
        value: 'TD',
        title: 'TD',
      },
      CL: {
        key: 'CL',
        value: 'CL',
        title: 'CL',
      },
      CN: {
        key: 'CN',
        value: 'CN',
        title: 'CN',
      },
      CX: {
        key: 'CX',
        value: 'CX',
        title: 'CX',
      },
      CC: {
        key: 'CC',
        value: 'CC',
        title: 'CC',
      },
      CO: {
        key: 'CO',
        value: 'CO',
        title: 'CO',
      },
      KM: {
        key: 'KM',
        value: 'KM',
        title: 'KM',
      },
      CG: {
        key: 'CG',
        value: 'CG',
        title: 'CG',
      },
      CD: {
        key: 'CD',
        value: 'CD',
        title: 'CD',
      },
      CK: {
        key: 'CK',
        value: 'CK',
        title: 'CK',
      },
      CR: {
        key: 'CR',
        value: 'CR',
        title: 'CR',
      },
      CI: {
        key: 'CI',
        value: 'CI',
        title: 'CI',
      },
      HR: {
        key: 'HR',
        value: 'HR',
        title: 'HR',
      },
      CU: {
        key: 'CU',
        value: 'CU',
        title: 'CU',
      },
      CW: {
        key: 'CW',
        value: 'CW',
        title: 'CW',
      },
      CY: {
        key: 'CY',
        value: 'CY',
        title: 'CY',
      },
      CZ: {
        key: 'CZ',
        value: 'CZ',
        title: 'CZ',
      },
      DK: {
        key: 'DK',
        value: 'DK',
        title: 'DK',
      },
      DJ: {
        key: 'DJ',
        value: 'DJ',
        title: 'DJ',
      },
      DM: {
        key: 'DM',
        value: 'DM',
        title: 'DM',
      },
      DO: {
        key: 'DO',
        value: 'DO',
        title: 'DO',
      },
      EC: {
        key: 'EC',
        value: 'EC',
        title: 'EC',
      },
      EG: {
        key: 'EG',
        value: 'EG',
        title: 'EG',
      },
      SV: {
        key: 'SV',
        value: 'SV',
        title: 'SV',
      },
      GQ: {
        key: 'GQ',
        value: 'GQ',
        title: 'GQ',
      },
      ER: {
        key: 'ER',
        value: 'ER',
        title: 'ER',
      },
      EE: {
        key: 'EE',
        value: 'EE',
        title: 'EE',
      },
      ET: {
        key: 'ET',
        value: 'ET',
        title: 'ET',
      },
      FK: {
        key: 'FK',
        value: 'FK',
        title: 'FK',
      },
      FO: {
        key: 'FO',
        value: 'FO',
        title: 'FO',
      },
      FJ: {
        key: 'FJ',
        value: 'FJ',
        title: 'FJ',
      },
      FI: {
        key: 'FI',
        value: 'FI',
        title: 'FI',
      },
      FR: {
        key: 'FR',
        value: 'FR',
        title: 'FR',
      },
      GF: {
        key: 'GF',
        value: 'GF',
        title: 'GF',
      },
      PF: {
        key: 'PF',
        value: 'PF',
        title: 'PF',
      },
      TF: {
        key: 'TF',
        value: 'TF',
        title: 'TF',
      },
      GA: {
        key: 'GA',
        value: 'GA',
        title: 'GA',
      },
      GM: {
        key: 'GM',
        value: 'GM',
        title: 'GM',
      },
      GE: {
        key: 'GE',
        value: 'GE',
        title: 'GE',
      },
      DE: {
        key: 'DE',
        value: 'DE',
        title: 'DE',
      },
      GH: {
        key: 'GH',
        value: 'GH',
        title: 'GH',
      },
      GI: {
        key: 'GI',
        value: 'GI',
        title: 'GI',
      },
      GR: {
        key: 'GR',
        value: 'GR',
        title: 'GR',
      },
      GL: {
        key: 'GL',
        value: 'GL',
        title: 'GL',
      },
      GD: {
        key: 'GD',
        value: 'GD',
        title: 'GD',
      },
      GP: {
        key: 'GP',
        value: 'GP',
        title: 'GP',
      },
      GU: {
        key: 'GU',
        value: 'GU',
        title: 'GU',
      },
      GT: {
        key: 'GT',
        value: 'GT',
        title: 'GT',
      },
      GG: {
        key: 'GG',
        value: 'GG',
        title: 'GG',
      },
      GN: {
        key: 'GN',
        value: 'GN',
        title: 'GN',
      },
      GW: {
        key: 'GW',
        value: 'GW',
        title: 'GW',
      },
      GY: {
        key: 'GY',
        value: 'GY',
        title: 'GY',
      },
      HT: {
        key: 'HT',
        value: 'HT',
        title: 'HT',
      },
      HM: {
        key: 'HM',
        value: 'HM',
        title: 'HM',
      },
      VA: {
        key: 'VA',
        value: 'VA',
        title: 'VA',
      },
      HN: {
        key: 'HN',
        value: 'HN',
        title: 'HN',
      },
      HK: {
        key: 'HK',
        value: 'HK',
        title: 'HK',
      },
      HU: {
        key: 'HU',
        value: 'HU',
        title: 'HU',
      },
      IS: {
        key: 'IS',
        value: 'IS',
        title: 'IS',
      },
      IN: {
        key: 'IN',
        value: 'IN',
        title: 'IN',
      },
      ID: {
        key: 'ID',
        value: 'ID',
        title: 'ID',
      },
      IR: {
        key: 'IR',
        value: 'IR',
        title: 'IR',
      },
      IQ: {
        key: 'IQ',
        value: 'IQ',
        title: 'IQ',
      },
      IE: {
        key: 'IE',
        value: 'IE',
        title: 'IE',
      },
      IM: {
        key: 'IM',
        value: 'IM',
        title: 'IM',
      },
      IL: {
        key: 'IL',
        value: 'IL',
        title: 'IL',
      },
      IT: {
        key: 'IT',
        value: 'IT',
        title: 'IT',
      },
      JM: {
        key: 'JM',
        value: 'JM',
        title: 'JM',
      },
      JP: {
        key: 'JP',
        value: 'JP',
        title: 'JP',
      },
      JE: {
        key: 'JE',
        value: 'JE',
        title: 'JE',
      },
      JO: {
        key: 'JO',
        value: 'JO',
        title: 'JO',
      },
      KZ: {
        key: 'KZ',
        value: 'KZ',
        title: 'KZ',
      },
      KE: {
        key: 'KE',
        value: 'KE',
        title: 'KE',
      },
      KI: {
        key: 'KI',
        value: 'KI',
        title: 'KI',
      },
      KP: {
        key: 'KP',
        value: 'KP',
        title: 'KP',
      },
      KR: {
        key: 'KR',
        value: 'KR',
        title: 'KR',
      },
      KW: {
        key: 'KW',
        value: 'KW',
        title: 'KW',
      },
      KG: {
        key: 'KG',
        value: 'KG',
        title: 'KG',
      },
      LA: {
        key: 'LA',
        value: 'LA',
        title: 'LA',
      },
      LV: {
        key: 'LV',
        value: 'LV',
        title: 'LV',
      },
      LB: {
        key: 'LB',
        value: 'LB',
        title: 'LB',
      },
      LS: {
        key: 'LS',
        value: 'LS',
        title: 'LS',
      },
      LR: {
        key: 'LR',
        value: 'LR',
        title: 'LR',
      },
      LY: {
        key: 'LY',
        value: 'LY',
        title: 'LY',
      },
      LI: {
        key: 'LI',
        value: 'LI',
        title: 'LI',
      },
      LT: {
        key: 'LT',
        value: 'LT',
        title: 'LT',
      },
      LU: {
        key: 'LU',
        value: 'LU',
        title: 'LU',
      },
      MO: {
        key: 'MO',
        value: 'MO',
        title: 'MO',
      },
      MK: {
        key: 'MK',
        value: 'MK',
        title: 'MK',
      },
      MG: {
        key: 'MG',
        value: 'MG',
        title: 'MG',
      },
      MW: {
        key: 'MW',
        value: 'MW',
        title: 'MW',
      },
      MY: {
        key: 'MY',
        value: 'MY',
        title: 'MY',
      },
      MV: {
        key: 'MV',
        value: 'MV',
        title: 'MV',
      },
      ML: {
        key: 'ML',
        value: 'ML',
        title: 'ML',
      },
      MT: {
        key: 'MT',
        value: 'MT',
        title: 'MT',
      },
      MH: {
        key: 'MH',
        value: 'MH',
        title: 'MH',
      },
      MQ: {
        key: 'MQ',
        value: 'MQ',
        title: 'MQ',
      },
      MR: {
        key: 'MR',
        value: 'MR',
        title: 'MR',
      },
      MU: {
        key: 'MU',
        value: 'MU',
        title: 'MU',
      },
      YT: {
        key: 'YT',
        value: 'YT',
        title: 'YT',
      },
      MX: {
        key: 'MX',
        value: 'MX',
        title: 'MX',
      },
      FM: {
        key: 'FM',
        value: 'FM',
        title: 'FM',
      },
      MD: {
        key: 'MD',
        value: 'MD',
        title: 'MD',
      },
      MC: {
        key: 'MC',
        value: 'MC',
        title: 'MC',
      },
      MN: {
        key: 'MN',
        value: 'MN',
        title: 'MN',
      },
      ME: {
        key: 'ME',
        value: 'ME',
        title: 'ME',
      },
      MS: {
        key: 'MS',
        value: 'MS',
        title: 'MS',
      },
      MA: {
        key: 'MA',
        value: 'MA',
        title: 'MA',
      },
      MZ: {
        key: 'MZ',
        value: 'MZ',
        title: 'MZ',
      },
      MM: {
        key: 'MM',
        value: 'MM',
        title: 'MM',
      },
      NA: {
        key: 'NA',
        value: 'NA',
        title: 'NA',
      },
      NR: {
        key: 'NR',
        value: 'NR',
        title: 'NR',
      },
      NP: {
        key: 'NP',
        value: 'NP',
        title: 'NP',
      },
      NL: {
        key: 'NL',
        value: 'NL',
        title: 'NL',
      },
      NC: {
        key: 'NC',
        value: 'NC',
        title: 'NC',
      },
      NZ: {
        key: 'NZ',
        value: 'NZ',
        title: 'NZ',
      },
      NI: {
        key: 'NI',
        value: 'NI',
        title: 'NI',
      },
      NE: {
        key: 'NE',
        value: 'NE',
        title: 'NE',
      },
      NG: {
        key: 'NG',
        value: 'NG',
        title: 'NG',
      },
      NU: {
        key: 'NU',
        value: 'NU',
        title: 'NU',
      },
      NF: {
        key: 'NF',
        value: 'NF',
        title: 'NF',
      },
      MP: {
        key: 'MP',
        value: 'MP',
        title: 'MP',
      },
      NO: {
        key: 'NO',
        value: 'NO',
        title: 'NO',
      },
      OM: {
        key: 'OM',
        value: 'OM',
        title: 'OM',
      },
      PK: {
        key: 'PK',
        value: 'PK',
        title: 'PK',
      },
      PW: {
        key: 'PW',
        value: 'PW',
        title: 'PW',
      },
      PS: {
        key: 'PS',
        value: 'PS',
        title: 'PS',
      },
      PA: {
        key: 'PA',
        value: 'PA',
        title: 'PA',
      },
      PG: {
        key: 'PG',
        value: 'PG',
        title: 'PG',
      },
      PY: {
        key: 'PY',
        value: 'PY',
        title: 'PY',
      },
      PE: {
        key: 'PE',
        value: 'PE',
        title: 'PE',
      },
      PH: {
        key: 'PH',
        value: 'PH',
        title: 'PH',
      },
      PN: {
        key: 'PN',
        value: 'PN',
        title: 'PN',
      },
      PL: {
        key: 'PL',
        value: 'PL',
        title: 'PL',
      },
      PT: {
        key: 'PT',
        value: 'PT',
        title: 'PT',
      },
      PR: {
        key: 'PR',
        value: 'PR',
        title: 'PR',
      },
      QA: {
        key: 'QA',
        value: 'QA',
        title: 'QA',
      },
      RE: {
        key: 'RE',
        value: 'RE',
        title: 'RE',
      },
      RO: {
        key: 'RO',
        value: 'RO',
        title: 'RO',
      },
      RU: {
        key: 'RU',
        value: 'RU',
        title: 'RU',
      },
      RW: {
        key: 'RW',
        value: 'RW',
        title: 'RW',
      },
      BL: {
        key: 'BL',
        value: 'BL',
        title: 'BL',
      },
      SH: {
        key: 'SH',
        value: 'SH',
        title: 'SH',
      },
      KN: {
        key: 'KN',
        value: 'KN',
        title: 'KN',
      },
      LC: {
        key: 'LC',
        value: 'LC',
        title: 'LC',
      },
      MF: {
        key: 'MF',
        value: 'MF',
        title: 'MF',
      },
      PM: {
        key: 'PM',
        value: 'PM',
        title: 'PM',
      },
      VC: {
        key: 'VC',
        value: 'VC',
        title: 'VC',
      },
      WS: {
        key: 'WS',
        value: 'WS',
        title: 'WS',
      },
      SM: {
        key: 'SM',
        value: 'SM',
        title: 'SM',
      },
      ST: {
        key: 'ST',
        value: 'ST',
        title: 'ST',
      },
      SA: {
        key: 'SA',
        value: 'SA',
        title: 'SA',
      },
      SN: {
        key: 'SN',
        value: 'SN',
        title: 'SN',
      },
      RS: {
        key: 'RS',
        value: 'RS',
        title: 'RS',
      },
      SC: {
        key: 'SC',
        value: 'SC',
        title: 'SC',
      },
      SL: {
        key: 'SL',
        value: 'SL',
        title: 'SL',
      },
      SG: {
        key: 'SG',
        value: 'SG',
        title: 'SG',
      },
      SX: {
        key: 'SX',
        value: 'SX',
        title: 'SX',
      },
      SK: {
        key: 'SK',
        value: 'SK',
        title: 'SK',
      },
      SI: {
        key: 'SI',
        value: 'SI',
        title: 'SI',
      },
      SB: {
        key: 'SB',
        value: 'SB',
        title: 'SB',
      },
      SO: {
        key: 'SO',
        value: 'SO',
        title: 'SO',
      },
      ZA: {
        key: 'ZA',
        value: 'ZA',
        title: 'ZA',
      },
      GS: {
        key: 'GS',
        value: 'GS',
        title: 'GS',
      },
      SS: {
        key: 'SS',
        value: 'SS',
        title: 'SS',
      },
      ES: {
        key: 'ES',
        value: 'ES',
        title: 'ES',
      },
      LK: {
        key: 'LK',
        value: 'LK',
        title: 'LK',
      },
      SD: {
        key: 'SD',
        value: 'SD',
        title: 'SD',
      },
      SR: {
        key: 'SR',
        value: 'SR',
        title: 'SR',
      },
      SJ: {
        key: 'SJ',
        value: 'SJ',
        title: 'SJ',
      },
      SZ: {
        key: 'SZ',
        value: 'SZ',
        title: 'SZ',
      },
      SE: {
        key: 'SE',
        value: 'SE',
        title: 'SE',
      },
      CH: {
        key: 'CH',
        value: 'CH',
        title: 'CH',
      },
      SY: {
        key: 'SY',
        value: 'SY',
        title: 'SY',
      },
      TW: {
        key: 'TW',
        value: 'TW',
        title: 'TW',
      },
      TJ: {
        key: 'TJ',
        value: 'TJ',
        title: 'TJ',
      },
      TZ: {
        key: 'TZ',
        value: 'TZ',
        title: 'TZ',
      },
      TH: {
        key: 'TH',
        value: 'TH',
        title: 'TH',
      },
      TL: {
        key: 'TL',
        value: 'TL',
        title: 'TL',
      },
      TG: {
        key: 'TG',
        value: 'TG',
        title: 'TG',
      },
      TK: {
        key: 'TK',
        value: 'TK',
        title: 'TK',
      },
      TO: {
        key: 'TO',
        value: 'TO',
        title: 'TO',
      },
      TT: {
        key: 'TT',
        value: 'TT',
        title: 'TT',
      },
      TN: {
        key: 'TN',
        value: 'TN',
        title: 'TN',
      },
      TR: {
        key: 'TR',
        value: 'TR',
        title: 'TR',
      },
      TM: {
        key: 'TM',
        value: 'TM',
        title: 'TM',
      },
      TC: {
        key: 'TC',
        value: 'TC',
        title: 'TC',
      },
      TV: {
        key: 'TV',
        value: 'TV',
        title: 'TV',
      },
      UG: {
        key: 'UG',
        value: 'UG',
        title: 'UG',
      },
      UA: {
        key: 'UA',
        value: 'UA',
        title: 'UA',
      },
      AE: {
        key: 'AE',
        value: 'AE',
        title: 'AE',
      },
      GB: {
        key: 'GB',
        value: 'GB',
        title: 'GB',
      },
      US: {
        key: 'US',
        value: 'US',
        title: 'US',
      },
      UM: {
        key: 'UM',
        value: 'UM',
        title: 'UM',
      },
      UY: {
        key: 'UY',
        value: 'UY',
        title: 'UY',
      },
      UZ: {
        key: 'UZ',
        value: 'UZ',
        title: 'UZ',
      },
      VU: {
        key: 'VU',
        value: 'VU',
        title: 'VU',
      },
      VE: {
        key: 'VE',
        value: 'VE',
        title: 'VE',
      },
      VN: {
        key: 'VN',
        value: 'VN',
        title: 'VN',
      },
      VG: {
        key: 'VG',
        value: 'VG',
        title: 'VG',
      },
      VI: {
        key: 'VI',
        value: 'VI',
        title: 'VI',
      },
      WF: {
        key: 'WF',
        value: 'WF',
        title: 'WF',
      },
      EH: {
        key: 'EH',
        value: 'EH',
        title: 'EH',
      },
      YE: {
        key: 'YE',
        value: 'YE',
        title: 'YE',
      },
      ZM: {
        key: 'ZM',
        value: 'ZM',
        title: 'ZM',
      },
      ZW: {
        key: 'ZW',
        value: 'ZW',
        title: 'ZW',
      },
    },
  },
  defaults: {
    title: 'Defaults',
    items: {},
  },
};

export const PROJECT_WITH_LIFETIME_EXPORT_LOGIC = [
    'yoga', 'fasteasy', 'mycoach', 'menscoach', 'calorie-tracker', 'walking', 'fasteasy_new',
];

export const PROJECT_WITH_WEB_CABINET_LOGIN = ['yoga'];

export const PROJECTS_WITH_PUBLIC_ID_FILTER = ['yoga'];

export const DESCRIPTORS = [
    {
        id: 'Checkout',
        name: 'Checkout',
    },
    {
        id: 'Adyen',
        name: 'Adyen',
    },
    {
        id: 'Walletto',
        name: 'Walletto',
    },
      {
        id: 'USA',
        name: 'USA',
    },
      {
        id: 'UAE',
        name: 'UAE',
    },
];
